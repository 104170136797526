export const ExternalLinkIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 12 12" style={{ fillRule:`evenodd`, clipRule:`evenodd`, strokeLinejoin:`round`, strokeMiterlimit:1.5 }}>
            <g transform="matrix(1,0,0,1,-929,-159)">
                <g transform="matrix(0.271094,0,0,0.271094,804.859,-43.9993)">
                    <g id="icon-link">
                        <g>
                            <g transform="matrix(0.713329,-0.00588692,0.00588692,0.713329,368.109,553.831)">
                                <path d="M154.376,279.954L180,280L179.954,305.624" style={{ fill:`none`, strokeWidth:`7.76px` }}/>
                            </g>
                            <g transform="matrix(0.463683,-0.4714,0.4714,0.463683,276.025,704.882)">
                                <path d="M140,290L183,290" style={{ fill:`none`, strokeWidth:`8.37px` }}/>
                            </g>
                        </g>
                        <path d="M476.962,759.2L461.613,759.2L461.613,789.132L491.544,789.132L491.544,773.782" style={{ fill:`none`, strokeWidth:`5.53px` }}/>
                    </g>
                </g>
            </g>
        </svg>
    )
}